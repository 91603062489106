import {
  Client,
  EndUserInfoRequest,
  OrderRequest,
  ShippingPlace,
} from "@today/api/taker"
import BaseBinder, {
  Column,
  Group,
  KeyMerger,
  Merger,
  throwErr,
  ValidationResult,
} from "./common"

type YesOrNo = "Y" | "N"

export type Row = {
  clientShippingId: string
  clientOrderId: string
  readyTime: string
  shippingPlace: string
  pickingLocation: string
  senderName: string
  receiverName: string
  receiverPhone: string
  receiverPostalCode: string
  receiverAddress: string
  accessMethod: string
  preference: string
  sellerName: string
  channel: string
  productName: string
  productCount: string
  productCategory: string
  productFragile: YesOrNo
  productPrice: string
  productCode: string
  isReturning: YesOrNo
  originalInvoiceNumber: string
}

export default class Legacy extends BaseBinder<Row> {
  readonly columns: Column<Row>[] = [
    { label: "고객 주문번호", key: "clientOrderId" },
    { label: "고객 배송번호", key: "clientShippingId", optional: true },
    { label: "고객 주문일시", key: "readyTime" },
    { label: "출고지", key: "shippingPlace" },
    { label: "피킹 위치", key: "pickingLocation" },
    { label: "발송자명", key: "senderName" },
    { label: "고객명", key: "receiverName" },
    { label: "고객 전화번호", key: "receiverPhone" },
    { label: "고객 우편번호", key: "receiverPostalCode", optional: true },
    { label: "고객 주소", key: "receiverAddress" },
    { label: "고객 건물 출입방법", key: "accessMethod" },
    { label: "고객 요청사항", key: "preference" },
    { label: "셀러명", key: "sellerName" },
    { label: "판매채널", key: "channel" },
    { label: "상품명", key: "productName" },
    { label: "상품 수량", key: "productCount" },
    { label: "상품 카테고리", key: "productCategory" },
    { label: "상품 깨짐주의", key: "productFragile" },
    { label: "상품 가액", key: "productPrice" },
    { label: "상품 코드", key: "productCode" },
    { label: "반품 여부", key: "isReturning" },
    { label: "반품 원송장번호", key: "originalInvoiceNumber" },
  ]
  readonly merger: Merger<Row> = new KeyMerger(["clientOrderId"])
  readonly shouldSupplyShippingPlace: boolean = true
  // TODO: Client.phoneNumber 필드 생기기 전까지 사용하는 임시 필드
  readonly senderPhone: string = "-"

  protected validateRow(row: Row, index: number): ValidationResult {
    const throwRowErr = (msg: string) => throwErr(index + 1, msg)
    if (!row.receiverName) throwRowErr("수취인 성명이 입력되지 않았습니다.")
    if (!row.receiverAddress) throwRowErr("수취인 주소가 입력되지 않았습니다.")
    if (!row.receiverPhone)
      throwRowErr("수취인 전화번호가 입력되지 않았습니다.")
    if (!row.productName) throwRowErr("품목명이 입력되지 않았습니다.")
    if (isNaN(parseInt(row.productCount)))
      throwRowErr("수량이 숫자 형태가 아닙니다.")
    if (parseInt(row.productCount) <= 0)
      throwRowErr("수량은 1 이상의 값이어야 합니다.")
    // TODO
    return {
      errors: [],
      warnings: [],
    }
  }

  private checkGroupColumns(
    group: Group<Row>,
    column: keyof Row,
    optional = false
  ) {
    group.rows
      .map((g) => g[column])
      .reduce((prev, v, i) => {
        if (optional && !v) return prev
        if (optional && !prev) return v
        if (prev !== v)
          throwErr(
            group.indices[i] + 1,
            `합포장 오류: ${group.indices[0] + 1}번째 줄과 ${(() => {
              const label = this.columns.find((c) => c.key === column)!.label
              return Array.isArray(label) ? label[0] : label
            })()} 값이 일치하지 않습니다.`
          )
        return v
      })
  }

  protected validateGroup(group: Group<Row>): ValidationResult {
    if (!group.rows.length) throw new Error("no rows in merged group")
    this.checkGroupColumns(group, "readyTime")
    this.checkGroupColumns(group, "receiverName")
    this.checkGroupColumns(group, "receiverAddress")
    this.checkGroupColumns(group, "receiverPhone")
    this.checkGroupColumns(group, "clientOrderId")
    this.checkGroupColumns(group, "isReturning")
    this.checkGroupColumns(group, "originalInvoiceNumber")
    // TODO
    return {
      errors: [],
      warnings: [],
    }
  }

  protected useSkipTakeOut(): boolean {
    return false
  }

  protected convertGroupToRequest(
    group: Group<Row>,
    client: Client,
    shippingPlace?: ShippingPlace | ShippingPlace[]
  ): OrderRequest {
    if (Array.isArray(shippingPlace))
      throw new Error("출고지 오류가 발생했습니다. 담당자에게 연락 바랍니다.")
    if (!shippingPlace) throw new Error("출고지가 선택되지 않았습니다.")
    function find(key: keyof Row) {
      return group.rows.map((g) => g[key]).find((e) => !!e)
    }
    const isReturning = find("isReturning") === "Y"
    const sender: EndUserInfoRequest = {
      shippingPlaceId: shippingPlace.id,
      name: find("senderName") ?? client.name,
      phone: this.senderPhone,
      address: `${shippingPlace.addressInfo.streetBaseAddress} ${shippingPlace.addressInfo.streetDetailAddress}`,
      accessMethod: "",
      preference: "",
    }
    const receiver: EndUserInfoRequest = {
      name: find("receiverName")!,
      phone: find("receiverPhone")!,
      address: find("receiverAddress")!,
      accessMethod: find("accessMethod"),
      preference: find("preference"),
    }
    return {
      clientOrderId: find("clientOrderId"),
      clientShippingId: find("clientShippingId"),
      sender: isReturning ? receiver : sender,
      receiver: isReturning ? sender : receiver,
      products: group.rows.flatMap((row) =>
        row.productName
          .split(";")
          .filter((name) => name)
          .map((name) => ({
            name,
            price: parseInt(row.productPrice ?? "0"),
            sellerName: find("sellerName"),
            clientProductId: row.productCode.toString(),
            count: +row.productCount,
            fragile: row.productFragile === "Y",
            pickingLocation: row.pickingLocation,
          }))
      ),
      returningInfo: isReturning
        ? {
            invoiceNumber: find("originalInvoiceNumber")!.replace(/-/g, ""),
          }
        : null,
      developerPayload: JSON.stringify({
        "@today": {
          channel: find("channel"),
        },
      }),
    }
  }
}
