import {
  Client,
  EndUserInfoRequest,
  OrderRequest,
  ShippingPlace,
} from "@today/api/taker"
import { Group, Merger, NoMerger, throwErr, ValidationResult } from "./common"
import Legacy, { Row } from "./legacy"

const SHIPPING_PLACE_MAP: {
  [key: string]: {
    id: string
    address: string
  }
} = {
  민트하우스: {
    id: "7e4e8a60-2e00-45d7-85ea-a2da521eb4d7",
    address: "서울 강남구 테헤란로 84길 13 금원빌딩 3층, 302호",
  },
  엠비리페어: {
    id: "c0183561-c826-433e-b34f-4a34b19bc12e",
    address: "서울시 강남구 학동로 317, 201호",
  },
  노블레스: {
    id: "25209cf6-207b-40f5-a7fd-9f6520bf78d0",
    address: "서울시 강남구 도산대로 85길 30, 208호",
  },
  아트크린: {
    id: "caac3f37-c88f-418f-b61f-375b925b77de",
    address: "서울 동작구 동작대로29길 119, 극동114동상가 1층 105호",
  },
}

export default class MintHouseRepair extends Legacy {
  readonly shouldSupplyShippingPlace = false
  readonly merger: Merger<Row> = new NoMerger()

  protected validateRow(row: Row, index: number): ValidationResult {
    super.validateRow(row, index)
    const throwRowErr = (msg: string) => throwErr(index + 1, msg)
    if (!row.shippingPlace) {
      throwRowErr("출고지가 입력되지 않았습니다.")
    }
    if (!SHIPPING_PLACE_MAP[row.shippingPlace]) {
      throwRowErr("출고지 이름이 등록되지 않았습니다.")
    }
    // TODO
    return {
      errors: [],
      warnings: [],
    }
  }

  protected convertGroupToRequest(
    group: Group<Row>,
    client: Client,
    NOT_USED_SHIPPING_PLACE?: ShippingPlace | ShippingPlace[]
  ): OrderRequest {
    function find(key: keyof Row) {
      return group.rows.map((g) => g[key]).find((e) => !!e)
    }
    const isReturning = find("isReturning") === "Y"
    const shippingPlaceLabel = find("shippingPlace")!
    const shippingPlace = SHIPPING_PLACE_MAP[shippingPlaceLabel]!
    const sender: EndUserInfoRequest = {
      shippingPlaceId: shippingPlace.id,
      name: find("senderName") ?? client.name,
      phone: this.senderPhone,
      address: shippingPlace.address,
      accessMethod: "",
      preference: "",
    }
    const receiver: EndUserInfoRequest = {
      name: find("receiverName")!,
      phone: find("receiverPhone")!,
      address: find("receiverAddress")!,
      accessMethod: find("accessMethod"),
      preference: find("preference"),
    }
    return {
      clientOrderId: find("clientOrderId"),
      clientShippingId: find("clientShippingId"),
      sender: isReturning ? receiver : sender,
      receiver: isReturning ? sender : receiver,
      products: group.rows.flatMap((row) =>
        row.productName
          .split(";")
          .filter((name) => name)
          .map((name) => ({
            name,
            price: parseInt(row.productPrice ?? "0"),
            sellerName: find("sellerName"),
            clientProductId: row.productCode.toString(),
            count: +row.productCount,
            fragile: row.productFragile === "Y",
            pickingLocation: row.pickingLocation,
          }))
      ),
      returningInfo: isReturning
        ? {
            invoiceNumber: find("originalInvoiceNumber")!.replace(/-/g, ""),
          }
        : null,
      developerPayload: JSON.stringify({
        "@today": {
          channel: find("channel"),
        },
      }),
    }
  }
}
